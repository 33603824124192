
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Table as TableModel } from '@/interfaces/models/Table';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import VenueApiService from '@/api/http/VenueApiService';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import Filter from '@/interfaces/api/Filter';

const venue = namespace('venue');
const table = namespace('table');

@Component({
  components: { VEmptyState, TableTable, VWrapper },
})
export default class Table extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;

  @table.State('items') public tables!: Table[];
  @table.State((state) => state.pagination.total) public total!: number;
  @table.Action('fetch') public getTables!: any;
  @table.Action('destroy') public destroy!: any;
  @table.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @table.Action('togleActive') public togleActiveReq!: any;

  public $refs!: {
    table: InstanceType<typeof TableTable> & { getSelected: () => TableModel[] };
  };

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      this.$startLoading('table');
      this.setFilter({ venue: this.venue._id });
      this.getTables({ page: 1 });
      this.$stopLoading('table');
    }
  }

  public exportQrs() {
    const selected: TableModel[] = this.$refs.table.getSelected();
    if (selected.length === 0) {
      this.notifyInfo('common.selectItems');
      return;
    }

    const api: VenueApiService = new VenueApiService();
    api.exportTableQrs(selected.map((t: TableModel) => t._id)).then((res: AxiosResponse) => {
      toUrl(res.data, 'tables.zip');
    });
  }

  public async togleActive({ item, value }: { item: TableModel; value: boolean }) {
    this.$startLoading('activeTableCheckbox');
    await this.togleActiveReq({ ...item, active: !value, id: item._id });
    this.$stopLoading('activeTableCheckbox');
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      this.$startLoading('table');
      this.setFilter({ venue: this.venue._id });
      this.getTables({ page: 1 });
      this.$stopLoading('table');
    }
  }

  public deleteTable(table: Table) {
    this.$startLoading('table');
    this.destroy(table);
    this.$stopLoading('table');
  }
}
